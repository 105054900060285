import React from "react"
import styles from "./blockTopBottomImg.module.css"

const BlockTopBottomImg = ({ bgColor, data }) => {
  const {
    boxTitle,
    boxText,
    text,
    img: {
      uri: { url: image },
    },
  } = data
  return (
    <section className={styles.blockTopBottomImg}>
      <div className={styles.blockTopBottomImg__titleText}>
        <h3>{boxTitle}</h3>
        <p>{boxText}</p>
      </div>
      <div className={styles.blockTopBottomImg__block__text}>
        <p>{text}</p>
      </div>
      <div className={styles.blockTopBottomImg__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt={boxTitle} />
      </div>
    </section>
  )
}
export default BlockTopBottomImg
