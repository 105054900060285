import { useStaticQuery, graphql } from "gatsby"

export const useWorkPage = () => {
  const { allNodeWorkManagement } = useStaticQuery(
    graphql`
      query WorkPage {
        allNodeWorkManagement {
          edges {
            node {
              field_imgheightcentertext_text_
              field_imgheightcentertext_title_
              field_imgtextmargintop_text_ {
                processed
              }
              field_imgtextmargintop_title_
              field_intro_box_text_
              field_intro_box_title_
              field_intro_text_
              field_intro_title_
              field_textblackimg_text_ {
                processed
              }
              field_textblackimg_title_
              field_textsmallimg_text_
              field_topbottomimg_box_text_
              field_topbottomimg_box_title_
              field_topbottomimg_text_
              relationships {
                field_full_width_img {
                  uri {
                    url
                  }
                }
                field_imgheightcentertext_img_ {
                  uri {
                    url
                  }
                }
                field_imgtextmargintop_img_ {
                  uri {
                    url
                  }
                }
                field_intro_bgimg_ {
                  uri {
                    url
                  }
                }
                field_textblackimg_img_ {
                  uri {
                    url
                  }
                }
                field_textsmallimg_img_ {
                  uri {
                    url
                  }
                }
                field_topbottomimg_box_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeWorkManagement.edges[0].node
}
