import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockBgImageIntro from "../components/BlockBgImageIntro/BlockBgImageIntro"
import BlockFullWidthImg from "../components/BlockFullWidthImg/BlockFullWidthImg"
import BlockImgHeightCenterText from "../components/BlockImgHeightCenterText/BlockImgHeightCenterText"
import BlockTopBottomImg from "../components/BlockTopBottomImg/BlockTopBottomImg"
import BlockImgTextMarginTop from "../components/BlockImgTextMarginTop/BlockImgTextMarginTop"
import BlockTextBlackImg from "../components/BlockTextBlackImg/BlockTextBlackImg"
import BlockTextSmallImg from "../components/BlockTextSmallImg/BlockTextSmallImg"
import Footer from "../components/Footer/Footer"

import { useWorkPage } from "../hooks/use-workPage"

const WorkManagement = () => {
  const workData = useWorkPage()
  const imageIntroData = {
    boxText: workData.field_intro_box_text_,
    boxTitle: workData.field_intro_box_title_,
    title: workData.field_intro_title_,
    text: workData.field_intro_text_,
    bgImg: workData.relationships.field_intro_bgimg_,
  }
  const fullWidthImgData = {
    img: workData.relationships.field_full_width_img,
  }
  const imgHeightCenterTextData = {
    title: workData.field_imgheightcentertext_title_,
    text: workData.field_imgheightcentertext_text_,
    img: workData.relationships.field_imgheightcentertext_img,
  }
  const topBottomImgData = {
    boxText: workData.field_topbottomimg_box_text_,
    boxTitle: workData.field_topbottomimg_box_title_,
    text: workData.field_topbottomimg_text_,
    img: workData.relationships.field_topbottomimg_box_img_,
  }
  const imgTextMarginTopData = {
    title: workData.field_imgtextmargintop_title_,
    text: workData.field_imgtextmargintop_text_.processed,
    img: workData.relationships.field_imgtextmargintop_img_,
  }
  const textBlackImgData = {
    title: workData.field_textblackimg_title_,
    text: workData.field_textblackimg_text_.processed,
    img: workData.relationships.field_textblackimg_img_,
  }
  const blockTextSmallImgData = {
    text: workData.field_textsmallimg_text_,
    img: workData.relationships.field_textsmallimg_img,
  }

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <BlockBgImageIntro data={imageIntroData} />
        <BlockFullWidthImg img={fullWidthImgData} />
        <BlockImgHeightCenterText data={imgHeightCenterTextData} />
        <BlockTopBottomImg data={topBottomImgData} />
        <BlockImgTextMarginTop data={imgTextMarginTopData} />
        <BlockTextBlackImg data={textBlackImgData} />
        {/* <BlockTextSmallImg data={blockTextSmallImgData} /> */}
        <Footer />
      </div>
    </div>
  )
}

export default WorkManagement
