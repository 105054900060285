import React from "react"
import styles from "./blockImgHeightCenterText.module.css"

const BlockImgHeightCenterText = ({ bgColor, data }) => {
  const {
    title,
    text,
    // img: {
    //   uri: { url: image },
    // },
  } = data
  return (
    <section className={styles.blockImgHeightCenterText}>
      <div className={styles.blockImgHeightCenterText__img}>
        <iframe
          width="100%"
          height="360"
          src="https://www.youtube.com/embed/EkbAjdD_Bog"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
        {/* <img src={`https://drupal.smartempo.com${image}`} alt={title} /> */}
      </div>
      <div className={styles.blockImgHeightCenterText__titleText}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </section>
  )
}
export default BlockImgHeightCenterText
