import React from "react"
import styles from "./blockBgImageIntro.module.css"

const BlockBgImageIntro = ({ bgColor, data }) => {
  const {
    title,
    text,
    boxTitle,
    boxText,
    bgImg: {
      uri: { url: image },
    },
  } = data
  return (
    <section className={styles.blockBgImageIntro}>
      <div
        className={styles.blockBgImageIntro__bg}
        style={{
          backgroundImage: `url(https://drupal.smartempo.com${image})`,
        }}
      />
      <div className={styles.blockBgImageIntro__title}>
        <p>{title}</p>
      </div>

      <div className={styles.blockBgImageIntro__blockText}>
        <p>{text}</p>
      </div>
      <div className={styles.blockBgImageIntro__titleText}>
        <h3>{boxTitle}</h3>
        <p>{boxText}</p>
      </div>
    </section>
  )
}
export default BlockBgImageIntro
